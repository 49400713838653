/**
 * @name: 商品管理-商品分类管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商品管理-商品分类管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IProductSort, IProductSortParams } from "./types";

export const sortPageApi = (params: IProductSortParams) => get<IProductSort[]>("/admin/sort/query/list", params)

export const sortPageListApi = (params: IProductSortParams) => get<IProductSort[]>("/admin/sort/query/list/new", params)

export const sortDetailApi = (id: string) => get<IProductSort>("/admin/sort/detail/" + id)

export const sortFCreateApi = (data: Partial<IProductSort>) => postJ("/admin/sort/create/firstLevel", data)

export const sortSCreateApi = (data: Partial<IProductSort>) => postJ("/admin/sort/create/secondLevel", data)

export const sortModifyApi = (data: Partial<IProductSort>) => postJ("/admin/sort/modify", data)
